/** Pinepointers 8: The Protector **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay, loadVideo } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Circ } from "gsap";

let globalState, data;

class PinepointersPage8 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[8];

        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            //1. play video
            this.refs.bgVideoRef.play();

            //2. animate highlighter
            //gsap.to(this.refs.highlighterWrapperRef, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });

            //3. Animate in Badge
            gsap.to(this.refs.protectorBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

        //add page track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2); //vo

        //If coming to this chapter by clicking prev
        if(globalState.transitionDirection === "prev") {
            globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);  //basetrack
        } 

        //dim main base track
        //globalState.baseAudioRef.updateVolume(1, 70);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        //const preloadData = globalState.assets[8];
        preloadImages([globalState.cdnUrl + data.nextTitle], this.preloadCallback);

        //Hls is added in gatsby-ssr.js
        let videoStream = globalState.videoUrl + data.bgVideo;
        
        loadVideo(this.refs.bgVideoRef, videoStream);
    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);

        window.removeEventListener("resize", this.updateDimensions);

        //if(globalState.transitionDirection === "next") {
            //globalState.baseAudioRef.updateTrack(2, false);
        //}
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");

        }
    }

    isTransitioning = () => {
        this.refs.bgVideoRef.pause();
        //pause animations
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);
    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/ends-and-odds/" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/ends-and-odds/" prevLocation="/pinepointers/7"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "3vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Richard remains larger than life. He has mellowed and come to terms with his new self – just before leaving Pine Point, he found out that a pile of accumulating symptoms added up to the early stages of MS. He has no regrets or anger, though he’s now a man who lives at the whim of schedules, lists, restrictions. It has focused him in other ways. Being from Pine Point has become one of his most vital characteristics: Richard loves the town, maybe more than anything else in his life." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <video ref="bgVideoRef" poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '406px', height: '286px', top: '438px', left: '789px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <img src={globalState.cdnUrl + data.badge} className="badge" alt="The Bully" style={{ left: '554px', top: 0 }} />
                        <img src={globalState.cdnUrl + data.badgeTop} className="badge protectorBadge" ref="protectorBadgeRef" alt="The Protector" style={{ left: '578px', top: '33px' }} />
                    </div>
                </div>
                )}

            </>
        );
    }


};

export default PinepointersPage8
